
/* ----------------------------------------------------------------

	content.scss

-----------------------------------------------------------------*/

/* ----------------------------------------------------------------
	Content
-----------------------------------------------------------------*/

body {
  background: $body-color;
}

#content {
	position: relative;
	background-color: #FFF;

	p { line-height: $line-height-content; }

	.content-wrap {
		position: relative;
		padding: 80px 0;
	}

	.container { position: relative; }
}

.grid-container {
	position: relative;
	overflow: hidden;
}

body:not(.device-touch) .grid-container { @include transition(height .4s linear); }

.title-circle {
  position: relative;
  &::before {
    content: "";
    position: absolute;
    width: 10px;
    height: 10px;
    top: -12px;
    left: 50%;
    margin-left: -5px;
    background: $body-color;
    border-radius: 50%;
  }
}

.border-left {
  border-left: 2px solid $theme-color !important;
}

.border-right {
  border-right: 2px solid $theme-color !important;
}
