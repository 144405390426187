/* ----------------------------------------------------------------
	Flip Cards
-----------------------------------------------------------------*/


.flip-card-front,
.flip-card-back {
	background-size: cover;
	background-position: center;
	-webkit-transition: -webkit-transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
	transition: -webkit-transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
	-o-transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
	transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
	transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1),
	-webkit-transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	width: 100%;
	height: 280px;
	border-radius: 6px;
	color: #FFF;
	font-size: 1.5rem;
}

.flip-card-front.no-after:after,
.flip-card-back.no-after:after { content: none }

.flip-card-front:after,
.flip-card-back:after {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;
	width: 100%;
	height: 100%;
	content: "";
	display: block;
	opacity: 0.6;
	background-color: #000;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	border-radius: 6px;
}

.flip-card {
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d;
	-webkit-perspective: 1000px;
	perspective: 1000px;
	&:hover {
		.flip-card-front,
		.flip-card-back {
			-webkit-transition: -webkit-transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
			transition: -webkit-transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
			-o-transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
			transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
			transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1),
			-webkit-transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
		}
	}
}

.flip-card-back {
	background-color: #666;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
}

.flip-card-inner {
	-webkit-transform: translateY(-50%) translateZ(60px) scale(0.94);
	transform: translateY(-50%) translateZ(60px) scale(0.94);
	top: 50%;
	position: absolute;
	left: 0;
	width: 100%;
	padding: 2rem;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	outline: 1px solid transparent;
	-webkit-perspective: inherit;
	perspective: inherit;
	z-index: 2;
	span {
		font-size: 18px;
		line-height: 20px;
		font-weight: 300;
	}
	p {
		position: relative;
		font-size: 16px;
		margin-bottom: 0;
		color: rgba(255, 255, 255, 0.7);
	}
}

.flip-card-back {
	-webkit-transform: rotateY(180deg);
	transform: rotateY(180deg);
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d;
}

.flip-card-front,
.flip-card:hover .flip-card-back {
	-webkit-transform: rotateY(0deg);
	transform: rotateY(0deg);
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d;
}

.flip-card:hover .flip-card-front {
	-webkit-transform: rotateY(-180deg);
	transform: rotateY(-180deg);
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d;
}

.top-to-bottom {
	.flip-card-back {
		-webkit-transform: rotateX(180deg);
		transform: rotateX(180deg);
		-webkit-transform-style: preserve-3d;
		transform-style: preserve-3d;
	}
	&:hover .flip-card-front {
		-webkit-transform: rotateX(-180deg);
		transform: rotateX(-180deg);
		-webkit-transform-style: preserve-3d;
		transform-style: preserve-3d;
	}
}

.top-to-bottom .flip-card-front,
.top-to-bottom:hover .flip-card-back {
	-webkit-transform: rotateX(0deg);
	transform: rotateX(0deg);
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d;
}

.owl-carousel .owl-stage { padding: 20px 0; }