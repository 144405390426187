
/*-----------------------------------------------------------------------------------

	Shortcodes: alerts.scss

-----------------------------------------------------------------------------------*/

/* ----------------------------------------------------------------
	Alerts
-----------------------------------------------------------------*/

.style-msg,
.style-msg2 {
	position: relative;
	display: block;
	color: $body-color;
	border-left: 4px solid rgba(0,0,0,0.1);
	margin-bottom: 20px;
}

.style-msg2 { border-left: 4px solid rgba(0,0,0,0.2); }

.successmsg {
	background: #C5EBD4;
	color: #184B21;
}

.errormsg {
	background: #FFCCCC;
	color: #740D0A;
}

.infomsg {
	background: #B9DFF4;
	color: #124F70;
}

.alertmsg {
	background: #FCE0B6;
	color: #975E06;
}

.style-msg .sb-msg,
.style-msg2 .msgtitle,
.style-msg2 .sb-msg {
	display: block;
	padding: 15px;
	border-left: 1px solid rgba(255,255,255,0.5);
	font-size: 14px;
	text-shadow: 1px 1px 1px rgba(255,255,255,0.6);
}

.style-msg2 .msgtitle {
	background: rgba(0,0,0,0.15);
	border-top: none;
	border-bottom: none;
	font-size: 15px;
	font-weight: bold;
}

.style-msg-light .sb-msg,
.style-msg-light .msgtitle {
	color: #FFF;
	text-shadow: 1px 1px 1px rgba(0,0,0,0.3);
}

.style-msg2 {
	.sb-msg {
		font-weight: normal;
	}
	ol,
	ul {
		margin: 0 0 0 30px;
	}
	ol {
		list-style-type: decimal;
	}
	ul {
		list-style-type: disc;
	}
}

.alert,
.sb-msg,
.msgtitle {
	i {
		position: relative;
		top: 1px;
		font-size: 14px;
		width: 14px;
		text-align: center;
		margin-right: 5px;
	}
}

.style-msg .close {
	position: absolute;
	top: 10px;
	left: auto;
	right: 10px;
}